.text-block {
  &--lg {
    .text-block__list__item {}

    margin-bottom: 185px;
  }

  .grid {
    & > h3 {
      margin-bottom: 90px;
    }
  }

  h3 {
    @media screen and (max-width: 1024px) {
      font-size: 1.21em;
    }
  }

  &__list {
    &__item {
      @include flexBetween;

      margin-bottom: 115px;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(even) {
        img {
          order: 2;
        }

        .text {
          order: 1;
        }

        .item__columns {
          order: 3;
        }

        @media screen and (max-width: 600px) {
          img, .text, .item__columns {
            order: 0;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 155px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 124px;
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 96px;
      }

      .text {
        width: 40%;

        @media screen and (max-width: 810px) {
          width: 50%;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
        }

        &--with-number {
          @media screen and (max-width: 1360px) {
            margin-left: 29px;
          }
        }

        h2 {
          position: relative;

          .number {
            position: absolute;
            top: -26px;
            left: -53px;
            width: 80px;
            height: 124px;
            z-index: -1;

            @media screen and (max-width: 1360px) {
              left: -29px;
            }

            @media screen and (max-width: 600px) {
              top: -25px;
            }
          }
        }
      }

      img {
        width: 50%;

        @media screen and (max-width: 1024px) {
          width: 45%;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 32px;
        }
      }

      p {
        margin: 32px 0;

        @media screen and (max-width: 1024px) {
          margin: 24px 0 32px 0;
        }

        @media screen and (max-width: 600px) {
          margin: 24px 0;
        }
      }

      .item__columns {
        margin-top: 74px;

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 -50px;

          @media screen and (max-width: 768px) {
            margin: 0 -28px;
          }

          @media screen and (max-width: 600px) {
            margin: (-32px) 0;
          }

          li {
            position: relative;
            padding: 0 50px;
            width: calc( (100% / 3) );

            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 1px;
              height: 103px;
              background-color: $green;

              @include translateY;

              @media screen and (max-width: 600px) {
                top: auto;
                bottom: 0;
                width: 91px;
                height: 1px;
                right: 50%;
                transform: translate(50%, 0);
              }
            }

            &:last-child {
              &::after {
                display: none;
              }
            }

            .item__columns__description {
              margin: 16px 0;
              line-height: 24px;
              max-height: 120px;
              overflow: hidden;
              transition: max-height 0.4s ease-out;
              will-change: max-height;
            }

            a {
              font-size: 0.86em;
            }

            @media screen and (max-width: 768px) {
              padding: 0 28px;
            }

            @media screen and (max-width: 600px) {
              width: 100%;
              padding: 32px 0;
            }
          }
        }

        &--two {
          ul {
            margin: 0 -160px;

            @media screen and (max-width: 1024px) {
              margin: 0 -80px;
            }

            @media screen and (max-width: 600px) {
              margin: 0;
            }

            li {
              width: calc( (100% / 2) );
              padding: 0 160px;

              @media screen and (max-width: 1024px) {
                padding: 0 80px;
              }

              @media screen and (max-width: 600px) {
                width: 100%;
                padding: 32px 0;
              }
            }
          }
        }
      }
    }
  }
}
