#pricing {
  h2 {
    margin-bottom: 56px;
  }

  .icon-single {
    &--checked {
      &::after {
        content: url("../../../img/icons/checkmark-green.svg");
      }
    }
  }
}

.pricing-table {
  display: flex;
  padding: 32px;
  background: $white;

  &__col {
    width: 23.33333%;
    border-bottom: 1px solid $grey-lightest;
    border-right: 1px solid $grey-lightest;
    position: relative;

    &:last-of-type {
      border-right: 0;
    }

    &--label {
      width: 30%;

      .pricing-table__row {
        label {
          justify-content: flex-start;
          font-size: 1em;
          color: $color-primary;
          text-transform: none;

          strong {
            font-size: 1.14em;
          }
        }

        &--colspan {
          label {
            font-size: 1em;
          }
        }
      }
    }
  }

  &__row {
    border-bottom: 1px solid $grey-lightest;

    &:last-of-type {
      border-bottom: 0;
    }

    label {
      height: 40px;

      @include flexCenter;

      font-size: 0.71em;
      color: black;
      text-transform: uppercase;
    }

    .icon-single {
      width: 100%;
      height: 40px;

      @include flexCenter;

      text-transform: none;
      color: $green;
      font-size: 0.85em;

      &--checked {
        &::after {
          content: url("../../../img/icons/checkmark-green.svg");
        }
      }

      &--empty {
        &::after {
          content: none;
        }
      }
    }

    &--colspan {
      height: 60px;
      position: relative;

      &::before {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        background: $white;
        right: -2px;
        top: 0;
      }

      label {
        height: 60px;
        font-size: 1em;
      }
    }

    &--empty {
      height: 190px;
    }

    &--head {
      height: 190px;

      @include flexCenter;

      flex-direction: column;
      border-top: 1px solid $grey-lightest;
      text-align: center;

      p {
        font-size: 1.14em;
        font-weight: $medium;

        @media all and (max-width: 1100px) {
          font-size: 1em;
        }
      }

      .btn {
        min-width: 100px;
      }
    }
  }
}

.price {
  font-size: 2em;
  margin-top: 15px;
  margin-bottom: 15px;

  sup {
    font-size: 0.5em;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
    display: inline-block;
  }
}

.pricing__best-value {
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -1px;
  left: -1px;
  pointer-events: none;

  &::before, &::after {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    background: $blue;
    top: 0;
    z-index: 1;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  span {
    position: absolute;
    padding: 4px 10px;
    color: $blue;
    background: $blue-lightest;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 0.714em;
    font-weight: 500;
    z-index: 2;
  }
}

.best-value__lines {
  &::before, &::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: $blue;
    left: 0;
    z-index: 1;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.pricing__mobile {
  &__item {
    background: $white;
    box-shadow: 4px 0px 18px rgba(9, 18, 49, 0.02);
    padding: 24px;
    position: relative;
    margin-bottom: 27px;
  }

  &__heading {
    @include flexCenter;

    flex-direction: column;
    padding: 24px;
    border-bottom: 1px solid $grey-lightest;

    p {
      font-size: 1.14em;
      font-weight: $medium;
    }
  }

  &__possibilities {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid $grey-lightest;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &--checks {
    padding-top: 24px;
    border-bottom: 1px solid $grey-lightest;

    span {
      font-weight: 500;
    }

    ul {
      margin-top: 20px;
      padding-bottom: 20px;

      li {
        display: flex;
        align-items: center;

        .icon-single {
          width: 30px;
          height: 35px;
          justify-content: flex-start;
          font-size: 1em;
        }
      }
    }
  }

  &--control {
    padding-top: 24px;
    text-align: center;

    .btn {
      min-width: 125px;
    }
  }
}

@media all and (min-width: 1100px) {
  .show-tablet {
    display: none !important;
  }
}

@media all and (max-width: 1100px) {
  .hide-tablet {
    display: none !important;
  }
}
