.people {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: calc( ((100vw - 1280px) / 2) + 10px );
    height: 100%;
    background-color: $white;
    z-index: 2;

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }

  .swiper-container {
    overflow: visible;

    @media screen and (max-width: 1280px) {
      overflow: hidden;
    }

    .arrows {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .arrow-next, .arrow-prev {
        height: 20px;
        cursor: pointer;
      }

      .arrow-next {
        margin-left: 18px;
      }

      .arrow-prev {
        margin-right: 18px;
        transform: rotate(180deg);
      }

      .swiper-button-disabled {
        cursor: auto;

        svg {
          path {
            fill: $grey-lightest;
            transition: fill 0.2s ease-out;
            will-change: fill;
          }
        }
      }
    }
  }

  h3 {
    margin-bottom: 56px;
  }

  &__card {
    margin-bottom: 60px;

    &__img {
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: calc( (479 / 411) * 100% );
    }

    &__text {
      display: flex;
      align-items: center;
      margin-top: 28px;

      @media screen and (max-width: 768px) {
        margin-top: 18px;
        flex-direction: column;
        align-items: flex-start;
      }

      h6 {
        margin-right: 20px;
        font-size: 1em;

        @media screen and (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }

      p {
        font-size: 1em;
      }
    }
  }
}
