.btn {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;

  &--primary {
    background-color: $blue;
    color: $white;
    border: 2px solid $blue;
    padding: 14px 22px;
    font-weight: $medium;
    border-radius: 3px;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

    @media (hover: hover) {
      &:hover {
        background-color: $blue-mid;
        border-color: $blue-mid;
      }
    }
  }

  &--secondary {
    color: $color-primary;
    padding: 14px 22px;
    border: 2px solid $blue;
    font-weight: $medium;
    border-radius: 3px;
    background: transparent;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $blue-mid;
      border-color: $blue-mid;
      color: $white;
    }

    @media (hover: hover) {
      &:hover {
        border-color: $blue-mid;
        background-color: $blue-mid;
      }
    }

    &--bigger {
      padding: 14px 61px;
    }

    &--white {
      background-color: $white;
      color: $black;

      @media (hover: hover) {
        &:hover {
          background-color: darken($grey-lighter, 5%);
        }
      }
    }
  }

  &--disabled {
    background-color: $grey-lighter;
    color: $grey-light;
    pointer-events: none;
  }
}
