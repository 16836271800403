#cookie-notice {
  display: block;
  position: fixed;
  bottom: 49px;
  left: 50%;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0px 4px 18px rgba(9, 18, 49, 0.08);
  border-radius: 3px;
  width: 921px;
  padding: 40px 32px;
  transform: translateX(-50%);
  z-index: 2;

  &.active {
    display: flex;
  }

  @media screen and (max-width: 1000px) {
    width: calc( 100% - 100px );
  }

  @media screen and (max-width: 768px) {
    width: calc( 100% - 40px );
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px;
  }

  h5 {
    @media screen and (max-width: 1000px) {
      width: 70%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
