#industries {
  h3 {
    margin-bottom: 32px;
  }

  .col {
    overflow: hidden;
  }

  .swiper-container {
    overflow: visible;
    margin: 37px 0;
  }

  .swiper-pagination {
    bottom: -30px;

    .swiper-pagination-bullet {
      margin: 0 5px;
      width: 10px;
      height: 10px;
      background-color: #DDE0E4;
      opacity: 1;
      transition: width 0.2s ease-out, background-color 0.2s ease-out;
      will-change: width, background-color;
    }

    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      @media (hover: hover) {
        &:hover {
          background-color: #0E1F38;
        }
      }
    }

    .swiper-pagination-bullet-active {
      background-color: #0E1F38;
      width: 57px;
      border-radius: 28px;
    }
  }

  .industries__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    padding-top: 100%;

    &::after {
      content: url("../../../img/swiper/hover-element.svg");
      position: absolute;
      bottom: -3px;
      right: 0;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.2s ease-out;
      will-change: opacity;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          opacity: 1;
        }

        .industries__item__img {
          opacity: 0.2;
        }

        .description, .link {
          opacity: 1;
        }
      }
    }

    &__img {
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 100% 115%;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      z-index: 1;
      transition: opacity 0.2s ease-out;
      will-change: opacity;
    }

    &__text {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 32px;

      @media screen and (max-width: 1100px) {
        padding: 24px;
      }

      @media screen and (max-width: 375px) {
        padding: 22px;
      }

      .description {
        margin-top: 40px;
        font-size: 1.14em;

        @media screen and (max-width: 375px) {
          margin-top: 20px;
          font-size: 1em;
        }
      }

      .description, .link {
        position: relative;
        opacity: 0;
        transition: opacity 0.2s ease-out;
        will-change: opacity;
        z-index: 2;
      }

      .title, .description, .link {
        color: $white;
      }

      .link {
        display: block;
        font-weight: $medium;

        &::after {
          content: url("../../../img/icons/arrow-right.svg");
          position: absolute;
          top: 2px;
          margin-left: 10px;
        }
      }
    }
  }
}
