header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 24px 0;
  z-index: 6;
  transition: background-color 0.2s ease-out, transform 0.2s ease-out;
  will-change: background-color, transform;

  &.active {
    box-shadow: 0px 4px 18px rgba(9, 18, 49, 0.02);
    background-color: $white;
    transform: translate(0, -68px);

    .mobile-menu {
      .menu__icon {
        &__line {
          background-color: $blue;
        }
      }
    }
  }

  &.mobile {
    background-color: $blue-dark !important;

    .logo {
      path {
        fill: $white !important;
      }
    }

    .mobile-menu {
      .menu__icon {
        &__line {
          background-color: $white;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 32px 0;

    &.active {
      transform: none;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 24px 0;
  }

  @media screen and (max-width: 320px) {
    padding: 14px 0;

    #nav-secondary {
      opacity: 0;
    }
  }

  &.dark {
    .mobile-menu {
      .menu__icon {
        &__line {
          background-color: $blue;
        }
      }
    }
  }

  &.light {
    .logo {
      g {
        path {
          fill: $white !important;
        }

        rect {
          fill: $white !important;
        }

        polygon {
          fill: $white !important;
        }
      }
    }

    nav {
      a:not(.btn) {
        color: $white;

        .dropdown-arrow {
          path {
            stroke: $white;
          }
        }
      }
    }

    .mobile-menu {
      .menu__icon {
        &__line {
          background-color: $white;
        }
      }
    }
  }

  .logo {
    position: relative;
    display: block;

    path {
      transition: fill 0.2s ease-out;
    }
  }

  nav {
    ul {
      display: flex;
      align-items: center;

      li {
        @media (hover: hover) {
          &:hover {
            .dropdown-arrow {
              transform: rotate(180deg);
            }
          }
        }

        a {
          color: $color-primary;

          .dropdown-arrow {
            margin-left: 4px;
            transition: transform 0.2s ease-out;
            will-change: transform;

            path {
              stroke: $color-primary;
            }
          }
        }
      }
    }
  }

  .header__top {
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    #nav-secondary {
      transition: opacity 0.2s ease-out;

      ul {
        justify-content: flex-end;

        li {
          position: relative;

          a:not(.btn) {
            padding: 40px 40px 40px 0;
          }
        }
      }
    }
  }

  .header__bottom {
    @include flexBetween;

    #nav-primary {
      @media screen and (max-width: 768px) {
        display: none;
      }

      ul {
        li {
          position: relative;

          a:not(.dropdown__item) {
            padding: 44px 44px 44px 0;
          }

          @media (hover: hover) {
            &:hover {
              .dropdown {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }

          .dropdown {
            position: absolute;
            top: 40px;
            right: 50%;
            display: flex;
            flex-wrap: wrap;
            padding: 24px 24px 4px 24px;
            background-color: $white;
            width: 576px;
            border-radius: 5px;
            transform: translate(50%, 0);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s ease-out;

            &__item {
              display: flex;
              align-items: flex-start;
              width: 50%;
              color: $color-primary;

              img {
                margin: 0 25px 0 0;
              }

              &__text {
                margin-bottom: 20px;

                h6 {
                  margin-bottom: 4px;
                }

                p {
                  font-size: 0.86em;
                  font-weight: $regular;
                  line-height: 1em;
                }
              }
            }
          }

          a {
            font-weight: $medium;
            transition: color 0.2s ease-out;

            .dropdown-arrow {
              path {
                transition: stroke 0.2s ease-out;
              }
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }

    .menu__icon {
      position: absolute;
      right: 0;

      @include translateY;

      &__line {
        width: 20px;
        height: 2px;
        background-color: $white;
        margin-bottom: 7px;
        transition: all 0.3s ease-out;
        will-change: transform, opacity;

        &:last-child {
          margin-bottom: 0px;
        }

        &:nth-child(2) {
          width: 15px;
        }
      }
    }

    &.active {
      .menu__icon {
        &__line {
          &:first-child {
            transform: translateY(9px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            transform: translateY(-9px) rotate(-45deg);
          }
        }
      }
    }
  }
}

#nav-mobile {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blue-dark;
  padding-bottom: 40px;
  overflow-y: scroll;
  z-index: 5;

  @media screen and (max-width: 768px) {
    display: flex;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.4s ease-out;
    will-change: transform;

    &.active {
      transform: translate3d(0, 0, 0);
    }
  }

  ul {
    padding-top: 106px;

    li {
      .nav__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        font-weight: $medium;
        padding: 20px 32px 20px 32px;

        &.open {
          .dropdown-arrow {
            transform: rotate(180deg);
          }
        }

        .dropdown-arrow {
          transition: transform 0.2s ease-out;
          will-change: transform;
        }
      }
    }
  }

  .btn {
    margin-top: 32px;
  }

  .dropdown {
    // display: none
    background-color: $blue-darker;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    will-change: max-height;

    &__item {
      display: block;
      padding: 16px 40px;

      &:first-child {
        padding-top: 24px;
      }

      &:last-child {
        padding-bottom: 24px;
      }
    }

    h6 {
      color: $white;
      font-weight: $regular;
      font-size: 0.86em;
    }
  }
}
