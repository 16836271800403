#contacts {
  position: relative;
  padding-top: 240px;
  padding-bottom: 311px;

  &::after {
    content: url("../../../img/illustration/contacts-map.svg");
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    @media screen and (max-width: 1024px) {
      left: -300px;
      bottom: -190px;
    }

    @media screen and (max-width: 768px) {
      left: -400px;
    }

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
    padding-top: 144px;
  }

  h2 {
    &:not(.section-title) {
      margin-bottom: 38px;

      @media screen and (max-width: 1024px) {
        font-size: 1.72em;
        margin-bottom: 32px;
      }
    }
  }

  .contacts__item {
    margin-top: 32px;

    p {
      margin-bottom: 8px;
      font-weight: $medium;
    }

    a {
      svg {
        margin-right: 24px;
      }

      &:last-child {
        svg {
          margin: 0px;
        }
      }
    }
  }
}
