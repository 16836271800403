@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaLight.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaLight-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaRegular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaRegular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaMedium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaMedium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaBold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaBold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaBlack.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Gordita";
  src: url("../../../fonts/GorditaBlack-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
