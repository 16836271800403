#roadmap {
  position: relative;
  background-image: url("../../../img/illustration/roadmap-gradient.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;
  padding-top: 0;
  margin-top: 128px;
  padding-bottom: 75px;

  .grid {
    position: relative;
  }

  .roadmap__title {
    position: absolute;
    transform: translateY(200px);

    @media screen and (max-width: 1200px) {
      transform: translateY(157px);
    }

    @media screen and (max-width: 780px) {
      transform: translateY(82px);
    }
  }

  .roadmap__desktop {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .roadmap__tablet {
    display: none;
    width: 100%;

    @media screen and (max-width: 1200px) {
      display: block;
    }

    @media screen and (max-width: 780px) {
      display: none;
    }
  }

  .roadmap__mobile {
    display: none;
    width: 100%;

    @media screen and (max-width: 780px) {
      display: block;
    }
  }

  .roadmap__text {
    position: absolute;
    max-width: 200px;

    &#one {
      left: 630px;
      top: 380px;

      @media screen and (max-width: 1200px) {
        left: 370px;
        top: 230px;
        text-align: right;
      }

      @media screen and (max-width: 780px) {
        left: 60px;
        top: 220px;
      }
    }

    &#two {
      left: 130px;
      top: 550px;

      @media screen and (max-width: 1200px) {
        left: 120px;
        top: 530px;
      }

      @media screen and (max-width: 780px) {
        top: 450px;
      }
    }

    &#three {
      left: 220px;
      top: 780px;

      @media screen and (max-width: 1200px) {
        left: 160px;
      }

      @media screen and (max-width: 780px) {
        text-align: right;
        left: 60px;
        top: 680px;
      }
    }

    &#four {
      left: 610px;
      top: 780px;

      @media screen and (max-width: 1200px) {
        left: 490px;
      }

      @media screen and (max-width: 780px) {
        left: 120px;
        top: 910px;
      }
    }

    &#five {
      left: 880px;
      top: 820px;
      text-align: right;

      @media screen and (max-width: 1200px) {
        left: 280px;
        top: 970px;
      }

      @media screen and (max-width: 780px) {
        left: 60px;
        top: 1140px;
      }
    }

    &__year {
      color: $green;
      font-weight: $medium;
      font-size: 1.14em;
    }

    &__title {
      font-weight: $medium;
      margin: 10px 0;
    }
  }
}
