.list-title {
  margin: 24px 0 16px 0 !important;
  font-size: 1.15em;
}

.list {
  ul {
    padding: 0;
    margin: 0;
  }

  &--square {
    ul {
      li {
        position: relative;
        padding-left: 14px;
        line-height: 24px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 6px;
          height: 6px;
        }
      }
    }

    &--green {
      li {
        &::before {
          background-color: $green;
        }
      }
    }

    &--blue {
      li {
        &::before {
          background-color: $blue;
        }
      }
    }
  }

  &--check {
    &--circle {
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 56px;

        li {
          position: relative;
          width: calc( (100% / 3) - 53px );
          padding-left: 79px;
          margin-bottom: 50px;
          margin-right: 53px;

          &:nth-child(3n+3) {
            margin-right: 0px;
          }

          &::before {
            content: url("../../../img/icons/checkmark-green.svg");

            @include flexCenter;

            position: absolute;
            top: 0;
            left: 0;
            background-color: $green-lightest;
            border-radius: 50%;
            width: 56px;
            height: 56px;
          }

          @media screen and (max-width: 900px) {
            width: calc( (100% / 2) - 56px );
            margin-right: 56px;

            &:nth-child(3n+3) {
              margin-right: 56px;
            }

            &:nth-child(2n+0) {
              margin-right: 0px;
            }
          }

          @media screen and (max-width: 900px) {
            width: 100%;
            margin-right: 0px !important;
            margin-bottom: 40px;
          }

          h5 {
            margin-bottom: 4px;
          }
        }
      }

      &--white {
        ul {
          li {
            padding-left: 64px;

            &::before {
              content: url("../../../img/icons/checkmark-white.svg");
              background-color: transparentize($white, 0.7);
              width: 40px;
              height: 40px;
            }

            p {
              color: $grey-lightest;
            }
          }
        }
      }
    }
  }

  &--icon {
    ul {
      li {
        position: relative;
        padding-left: 32px;
        line-height: 24px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }

        &::before {
          content: url("../../../img/icons/check-oval.svg");
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
    }
  }
}
