.form {
  @media screen and (max-width: 540px) {
    margin-top: 96px;
  }

  &--main {
    background-color: $blue-lightest;
    padding: 75px 120px 48px 120px;

    @media screen and (max-width: 1024px) {
      padding: 40px;
    }

    @media screen and (max-width: 540px) {
      padding: 40px 16px;
    }

    p {
      margin: 26px 0 33px 0;
    }

    .form__content {
      display: flex;
      flex-direction: column;

      input:not([type=checkbox]) {
        height: 40px;
        margin-bottom: 24px;
        padding: 0 12px;
      }

      input:not([type=checkbox]), textarea {
        font-family: $font-primary;
        border: 1px solid $grey-lightest;
        border-radius: 2px;
        color: $color-primary;
        outline: none;
      }

      textarea {
        height: 142px;
        resize: none;
        margin-bottom: 20px;
        padding: 8px 12px;
      }

      label:not([for=accept]) {
        font-weight: $medium;
        font-size: 0.7em;
        color: $grey;
        margin-bottom: 3px;
      }

      label[for=accept] {
        position: relative;
        display: flex;
        align-items: center;
        height: 20px;
        padding-left: 28px;
        margin-bottom: 36px;
        font-size: 0.89em;

        &.active {
          &::before {
            background-color: $blue;
            border-color: $blue;
          }

          &::after {
            display: block;
          }
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              border-color: $blue;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          background-color: $white;
          border: 2px solid $grey-lightest;
          border-radius: 3px;
          transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
          will-change: background-color, border-color;
        }

        &::after {
          display: none;
          content: url("../../../img/icons/form-checkmark.svg");
          position: absolute;
          top: 2px;
          left: 5px;
        }
      }
    }

    input[type=checkbox] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
}

::placeholder {
  color: $grey-light;
}
