.cards {
  .row {
    .col {
      &:last-child {
        .cards__item {
          margin-bottom: 0px;
        }
      }
    }
  }

  h3 {
    margin-bottom: 56px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 24px;
    border: 1px solid $grey-lightest;
    border-radius: 5px;
    color: $color-primary;
    margin-bottom: 24px;
    transition: box-shadow 0.2s ease-out;
    will-change: box-shadow;

    @media screen and (max-width: 540px) {
      padding: 16px;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: 0px 4px 18px rgba(0, 90, 119, 0.1);

        svg {
          path {
            fill: $blue;
          }
        }
      }
    }

    img {
      margin-right: 25px;
    }

    &__text {
      display: flex;
      flex-direction: column;

      h5 {
        margin-bottom: 8px;
      }
    }

    svg {
      position: absolute;

      @include translateY;

      right: 24px;

      path {
        transition: fill 0.2s ease-out;
        will-change: fill;
      }
    }
  }
}
