.companies {
  &--testimonial {
    h3 {
      margin-bottom: 0px !important;
    }

    .subtitle {
      margin: 24px 0 60px 0;
    }

    .swiper-container {
      background-color: $blue-lightest;

      .swiper-pagination {
        bottom: 29px;

        .swiper-pagination-bullet {
          background-color: $grey-lightest;
          opacity: 1;
          transition: background-color 0.2s ease-out;
        }

        .swiper-pagination-bullet-active {
          background-color: $blue;
        }
      }

      .arrows {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        z-index: 5;

        @include translateY;

        .arrow-prev, .arrow-next {
          width: 50px;
          height: 50px;
          cursor: pointer;

          &.swiper-button-disabled {
            pointer-events: none;

            svg {
              path {
                fill: $grey-light;
              }
            }
          }

          svg {
            position: absolute;

            @include translateY;

            path {
              fill: $blue;
            }
          }
        }

        .arrow-next {
          svg {
            right: 28px;
          }
        }

        .arrow-prev {
          transform: rotate(180deg);

          svg {
            left: 28px;
          }
        }
      }
    }
  }

  h3 {
    margin-bottom: 72px;
  }

  &__list {
    @include flexCenter;

    flex-wrap: wrap;

    img {
      margin-bottom: 32px;
    }
  }

  &__testimonial {
    @include flexCenter;

    flex-direction: column;
    align-items: center;
    padding: 64px;

    @media screen and (max-width: 1024px) {
      padding: 32px 76px;
      margin-bottom: 58px;
    }

    @media screen and (max-width: 600px) {
      padding: 32px 24px;
      margin-bottom: 52px;
    }

    .text {
      font-size: 1.14em;
      margin: 24px 0 29px 0;
    }

    .avatar {
      width: 40px;
      height: 40px;
    }

    .name {
      margin: 16px 0 4px 0;
    }

    .name, .text {
      font-weight: $medium;
    }

    .name, .position {
      font-size: 0.86em;
    }
  }
}
