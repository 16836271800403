.accordion-container {
  position: relative;
  max-width: 569px;
  height: auto;
  margin: 10px auto;

  > h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
}

.set {
  position: relative;
  width: 100%;
  height: auto;

  > span {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    cursor: pointer;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    transition: all 0.2s linear;

    img {
      float: right;
      margin-top: 2px;
      width: 16px;
    }

    &.active {
      color: #000;
    }
  }
}

.content {
  border-bottom: 1px solid #ddd;
  display: none;

  p {
    padding: 10px 15px;
    margin: 0;
    color: #333;
  }
}

.rotate_180 {
  transform: rotate(180deg);
  transition: 200ms ease;
}

.rotate_to_0 {
  transform: rotate(0);
  transition: 200ms ease;
}
