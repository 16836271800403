#loader {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: -6px;
  border: 6px solid #0096C7;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: loading 1500ms linear infinite;

  @media (max-width: 600px) {
    width: 160px;
    height: 160px;
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.loader_background-border {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 6px solid #EDEFF2;
  position: relative;
}

#loader circle {
  stroke-dashoffset: -327px;

  // transition: stroke-dashoffset 1s linear
  stroke-width: 6px;
}

#loader #bar {
  stroke: #0096C7;
}

.progressbar {
  display: block;
  height: 160px;
  width: 160px;
  margin: 2em auto;

  // box-shadow: 0 0 1em black
  border-radius: 100%;
  position: relative;
  transform: rotate(-85deg);
}

.dropzone-preview {
  .upload-item {
    margin-top: -30px;
  }
}
