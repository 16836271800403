.blue-box {
  @media screen and (max-width: 768px) {
    padding-top: 96px;
  }

  &__bg {
    background-color: $blue-lightest;
    background-image: url("../../../img/illustration/circle.svg");
    background-repeat: no-repeat;
    background-position: top left;
    height: 300px;

    @media screen and (max-width: 768px) {
      height: 250px;
    }

    @media screen and (max-width: 540px) {
      height: auto;
      padding: 50px;
    }
  }

  &__content-flex {
    @include flexBetween;

    padding: 62px 109px;

    @media screen and (max-width: 1024px) {
      padding: 64px;
    }

    @media screen and (max-width: 768px) {
      padding: 64px 32px;
    }

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    .blue-box__title {
      margin-right: 32px;

      @media screen and (max-width: 540px) {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }

  &__content-flex-center {
    @include flexCenter;

    .blue-box__title {
      p {
        margin: 16px 0 32px 0;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;

    @media screen and (max-width: 540px) {
      flex-direction: column;
    }

    &__item {
      width: 333px;
      height: 140px;

      @media screen and (max-width: 1024px) {
        width: 250px;
      }

      @media screen and (max-width: 768px) {
        width: 180px;
        height: 120px;
      }

      @media screen and (max-width: 540px) {
        width: auto;
        height: auto;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .title {
        color: $blue-dark;
        font-weight: $bold;
        font-size: 3.43em;

        @media screen and (max-width: 768px) {
          font-size: 3em;
        }
      }

      .description {
        color: $grey-darker;
        font-weight: $medium;
        font-size: 1.14em;
        margin-top: 13px;

        @media screen and (max-width: 768px) {
          font-size: 1em;
        }
      }
    }
  }

  .form {
    min-width: 300px;
    margin: 0 !important;

    label[for=accept] {
      margin-left: 0px;
    }

    @media screen and (max-width: 540px) {
      width: 100%;
      min-width: auto;
    }

    &--main {
      background-color: transparent;
      padding: 0;

      p {
        margin: 0 0 8px 0;
      }

      .form__box {
        position: relative;
        margin-bottom: 18px;
      }

      .send {
        ~ .message-send {
          display: none;
          position: absolute;
          font-size: 0.7em;
          bottom: 0;
          transform: translateY(100%);
          line-height: 12px;
          padding-top: 10px !important;
        }

        &.success {
          ~ .message-send {
            display: block;
            color: $green;
          }
        }
      }

      label[for=accept] {
        margin-bottom: 20px;
      }
    }
  }
}
