#intro {
  position: relative;
  display: flex;
  align-items: center;
  height: 828px;
  padding-bottom: 68px;

  @media screen and (max-width: 540px) {
    padding-top: 98px;
    height: 100vh;
  }

  // @media ( max-width: 540px ) and ( max-height: 780px )
  //   height: auto

  &::before {
    content: url("../../../img/hero/mask.svg");
    position: absolute;
    bottom: -6px;

    @include translateX;

    width: calc(100% + 2px);
    z-index: 2;

    @media screen and (max-width: 1024px) {
      width: 150%;
    }

    @media screen and (max-width: 540px) {
      width: 350%;
    }
  }

  &.intro--half {
    height: 495px;
  }

  &.intro--no-bg {
    height: auto !important;
    padding: 240px 0 0 0;

    @media screen and (max-width: 768px) {
      padding: 160px 0 0 0;
    }

    .intro__collage {
      display: flex;
      align-items: center;
      margin-top: 80px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__group {
        .intro__collage__wrapper {}

        .img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
          padding-top: calc( (501 / 761) * 100% );
        }

        &:first-child {
          width: 40%;
          margin-right: 16px;

          @media screen and (max-width: 768px) {
            display: flex;
            width: 100%;
            order: 2;
          }

          .intro__collage__wrapper {
            margin: 0 0 0 auto;

            &:first-child {
              width: 50%;
              margin-bottom: 16px;

              @media screen and (max-width: 768px) {
                margin: 0 8px 0 0;
              }
            }

            &:last-child {
              width: 80%;
              margin-top: 16px;

              @media screen and (max-width: 768px) {
                margin: 0 0 0 8px;
              }
            }

            @media screen and (max-width: 768px) {
              width: 50% !important;
            }
          }
        }

        &:last-child {
          width: 60%;
          margin-left: 16px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }

  & > .btn {
    position: absolute;

    @include translateX;

    bottom: 98px;
    z-index: 2;
  }

  .intro__el {
    img {
      position: absolute;
      bottom: 0;

      @include translateX;

      width: 100% !important;

      @media screen and (max-width: 1024px) {
        width: 150% !important;
      }

      @media screen and (max-width: 540px) {
        width: 350% !important;
      }
    }
  }

  .grid {
    z-index: 2;
  }

  .title-testimonial {
    margin-bottom: 109px;

    @media screen and (max-width: 768px) {
      margin-bottom: 50px;
    }
  }

  &.intro--dark {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    top: 0;
    width: 100%;

    h1, h2, p {
      color: $white;
    }

    p {
      margin: 24px 0 56px 0;
    }
  }

  &.intro--color {
    &--white {
      background: radial-gradient(195.66% 242.72% at 50% 25.65%, #FFFFFF 0%, #ECF8FC 100%);

      .intro__content {
        @media screen and (max-width: 768px) {
          text-align: center;
          margin-top: 36px;
        }

        @media (max-width: 540px) and (max-height: 780px) {
          margin-top: 0px;
        }
      }

      .intro__img {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: -20px;
          left: 15px;
          width: 65%;
          height: 70%;
          background-color: $blue-lightest;
          border-radius: 5px;
          z-index: -1;
        }

        @media screen and (max-width: 768px) {
          width: 60%;
          margin: auto;
        }

        @media screen and (max-width: 540px) {
          width: 100%;
        }
      }

      img {
        width: 100%;
      }
    }

    // @media screen and ( max-width: 768px )
    //   display: block
    //   width: 60%
    //   margin: auto

    // @media screen and ( max-width: 540px )
    //   width: 100%

    &--red {
      background-color: $red-soft;
      background-image: url("../../../img/hero/use-cases-hero.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60%;

      .intro__content {
        * {
          color: $white !important;
        }
      }
    }

    &--green {
      background-color: $green;
      background-image: url("../../../img/swiper/gaming.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60%;

      .intro__content {
        * {
          color: $white !important;
        }
      }
    }

    &--blue {
      background-color: $blue-dark;
      background-image: url("../../../img/swiper/trading.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60%;

      .intro__content {
        * {
          color: $white !important;
        }
      }
    }

    &--turquoise {
      background-color: $blue;
      background-image: url("../../../img/swiper/institution.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60%;

      .intro__content {
        * {
          color: $white !important;
        }
      }
    }

    &--purple {
      background-color: $purple;
      background-image: url("../../../img/swiper/payment.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60%;

      .intro__content {
        * {
          color: $white !important;
        }
      }
    }
  }

  .intro__content {
    &--testimonial {
      h3 {
        margin-bottom: 32px;
      }

      .intro__content__testimonial {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          justify-content: center;
        }

        .testimonial__text {
          font-weight: $medium;
          margin: 24px 0 16px 0;
        }

        .testimonial__name {
          text-align: left !important;

          .name {
            margin: 0 4px 0 14px;
            font-weight: $medium;

            @media screen and (max-width: 768px) {
              margin: 0px;
            }
          }

          @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            margin-left: 14px;
          }
        }

        .testimonial__avatar {
          width: 40px;
        }
      }
    }

    &:not(.intro__content--testimonial) {
      p {
        margin: 30px 0 44px 0;

        @media screen and (max-width: 540px) {
          margin: 20px 0 34px 0;
        }
      }
    }

    img:not(.testimonial__avatar) {
      width: 550px;

      @media screen and (max-width: 1024px) {
        width: 60%;
      }

      @media screen and (max-width: 540px) {
        width: 100%;
      }

      @media (max-width: 540px) and (max-height: 780px) {
        width: 60%;
      }

      @media screen and (max-width: 425px) {
        width: 100%;
      }

      @media (max-width: 425px) and (max-height: 640px) {
        width: 60%;
      }
    }
  }

  .intro__video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 828px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      height: 100%;
    }

    #video {
      width: 100%;

      @media screen and (max-width: 1500px) {
        height: 100%;
        width: auto;
      }

      @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;

        @include translateX;
      }
    }
  }
}
