.pagination {
  @include flexCenter;

  &__link {
    display: block;
    padding: 10px 16px;
    margin: 0 6px;
    color: $grey-light;
    font-weight: $medium;
    border-radius: 3px;

    &:not(.pagination__link--space):not(.pagination__link--active) {
      transition: color 0.2s ease-out;
      will-change: color;

      @media (hover: hover) {
        &:hover {
          color: $blue;
        }
      }
    }

    &--active {
      background-color: $blue;
      color: $white;
    }
  }

  &__prev {
    transform: rotate(180deg);
  }

  &__next, &__prev {
    margin: 0 20px;

    svg {
      height: 15px;

      path {
        transition: fill 0.2s ease-out;
        will-change: fill;
      }
    }

    @media (hover: hover) {
      &:hover {
        path {
          fill: $blue;
        }
      }
    }
  }
}
