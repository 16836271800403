#page-not-found {
  position: relative;
  display: flex;
  align-items: center;
  background: radial-gradient(195.66% 242.72% at 50% 25.65%, #FFFFFF 0%, #ECF8FC 100%);
  height: 828px;
  padding-top: 0px;

  @media screen and (max-width: 540px) {
    padding-top: 0px;
    height: 100vh;
  }

  &::before {
    content: url("../../../img/hero/mask.svg");
    position: absolute;
    bottom: -3px;

    @include translateX;

    width: 100%;
    z-index: 2;

    @media screen and (max-width: 1024px) {
      width: 150%;
    }

    @media screen and (max-width: 540px) {
      width: 350%;
    }
  }

  .intro__el {
    img {
      position: absolute;
      bottom: 3px;

      @include translateX;

      width: 100%;

      @media screen and (max-width: 1024px) {
        width: 150%;
      }

      @media screen and (max-width: 540px) {
        width: 350%;
      }
    }
  }

  h1 {
    font-size: 10.29em;

    @media screen and (max-width: 540px) {
      font-size: 7em;
    }
  }

  p {
    font-size: 1.14em;
    margin-bottom: 64px;

    @media screen and (max-width: 540px) {
      margin-bottom: 32px;
    }
  }
}
