@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin translateX {
  left: 50%;
  transform: translateX(-50%);
}

@mixin translateY {
  top: 50%;
  transform: translateY(-50%);
}

@mixin translateTop {
  top: 50%;
  transform: translateY(-50%);
}

@mixin fleXCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
