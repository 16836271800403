#career-single {
  padding-top: 200px;

  h1 {
    font-size: 45px;
    margin-bottom: 74px;
    font-weight: 500;
  }

  h2 {
    font-size: 31px;
    margin-bottom: 32px;
  }

  p {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 144px;
  }

  .list-check {
    margin-bottom: 50px;
  }

  .form {
    margin-top: 72px;
  }

  .link-back {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    text-decoration: none;
    font-weight: $medium;

    &__arrow {
      display: inline-block;
      transform: rotate(180deg);
      margin-right: 10px;

      &::before {
        content: url("../../../img/icons/swiper-arrow.svg");
      }
    }
  }
}
