////////////////////////////////////////////////////////////////////////
// THEME SETUP
////////////////////////////////////////////////////////////////////////

// Fonts (Include fonts here)
$font-primary: "Gordita", sans-serif;

// Font weights
$regular: 400;
$medium: 500;
$bold: 700;
$light: 300;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

// Font sizes
$fs-xs: 0.75em;
$fs-sm: 0.875em;
$fs-base: 1em;
$fs-lg: 1.125em;
$fs-xl: 1.25em;
$fs-2xl: 1.5em;
$fs-3xl: 1.7em;

// Font sizes (Define theme font sizes)
$body-font-size: 14px;
$body-line-height: 1.6em;

// Transitions (Define theme transitions)
$cubic: cubic-bezier(0.19, 1, 0.22, 1);

// Colors (Define theme main colors)
$color-primary: #00202B;
$blue-lightest: #ECF8FC;
$blue-light: #B2E1F1;
$blue: #3EB4DC;
$blue-dark: #1B3E6F;
$blue-mid: #3290B0;
$black: #000000;
$blue-darkest: #0E1F38;
$blue-darker: #153562;
$grey-dark: #737373;
$grey-darker: #57586E;
$grey: #8D8E9A;
$grey-light: #BBC0CE;
$grey-lighter: #F7F9FC;
$grey-lightest: #EDEFF2;
$white: #ffffff;
$red-soft: #F2796D;
$red: #FA635F;
$green: #4DD970;
$green-lightest: #EDFBF1;
$purple: #8E8CD8;
