footer {
  position: relative;
  margin-top: 128px;
  padding: 98px 0;
  background-color: $blue-darkest;
  color: $white;

  @media screen and (max-width: 768px) {
    margin-top: 96px;
  }

  &.bg {
    &::after {
      content: url("../../../img/footer/footer-dots.svg");
      position: absolute;
      top: 0;
      right: 185px;

      @media screen and (max-width: 1024px) {
        right: 68px;
      }

      @media screen and (max-width: 768px) {
        right: -168px;
      }
    }

    &::before {
      content: url("../../../img/footer/footer-circle.svg");
      position: absolute;
      top: 0;
      right: 0;

      @media screen and (max-width: 1024px) {
        right: -96px;
        width: 573px;
      }

      @media screen and (max-width: 768px) {
        right: -222px;
      }
    }
  }

  .col {
    &.order {
      @media screen and (max-width: 768px) {
        order: 2;
      }
    }
  }

  .footer__top {
    padding-bottom: 100px;
    margin-bottom: 100px;
    border-bottom: 1px solid $white;

    @media screen and (max-width: 1024px) {
      text-align: center;
    }

    p {
      font-size: 1.14em;
      color: $grey-light;
      margin: 10px 0 32px 0;

      @media screen and (max-width: 1024px) {
        font-size: 1em;
      }
    }
  }

  .footer__bottom-left {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &>a {
      display: block;
    }

    &__social {
      margin: 32px 0;

      a {
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    p {
      font-size: 0.8em;
    }

    @media screen and (max-width: 768px) {
      margin-top: 64px;
    }
  }

  .footer_iso_logo {
    width: 189px;
    margin-left: -69px;
    margin-top: 6px;
  }

  #footer-nav {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: calc((100vw / 12) - 20px);
    }

    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }

    ul {
      @media screen and (max-width: 1024px) {
        width: calc((100% / 3) - 92px);
        margin-right: 92px;

        &:nth-child(-n + 3) {
          margin-bottom: 56px;
        }
      }

      @media screen and (max-width: 768px) {
        width: calc((100% / 4) - 39px);
        margin-right: 39px;

        &:nth-child(-n + 3) {
          margin-bottom: 0px;
        }

        &:last-child {
          margin-top: 48px;
        }
      }

      @media screen and (max-width: 540px) {
        width: calc((100% / 2) - 39px);
        margin-bottom: 32px !important;

        &:last-child {
          margin-top: 0px;
        }
      }
    }

    li {
      a {
        color: $white;
        line-height: 22px;
      }

      &:not(.bold) {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0px;
        }

        a {
          opacity: 0.5;
          transition: opacity 0.2s ease-out;
          will-change: opacity;

          @media (hover: hover) {
            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &.bold {
        margin-bottom: 24px;

        a {
          font-size: 1.14em;
        }
      }
    }
  }
}

.footer-iso-logo1 {
  float: right;
  margin-right: -3em;

  img {
    margin-left: 0;
    margin-top: -34px;

    @media screen and (max-width: 1024px) {
      margin-left: 1.8em;
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      margin-left: -1.5em;
    }
  }

  @media screen and (max-width: 1024px) {
    float: none;
    margin-right: 0;
  }
}

.footer-iso-logo2 {
  float: right;

  img {
    width: 189px;

    @media screen and (max-width: 1024px) {
      top: -2.3em;
      position: relative;
    }
  }

  @media screen and (max-width: 1024px) {
    float: none;
  }
}

