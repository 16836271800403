i {
  font-style: normal;
}

// element floats and positioning
.fl-left {
  float: left;
}

.fl-right {
  float: right !important;
}

.fl-center {
  margin-left: auto;
  margin-right: auto;
}

.fl-none {
  float: none;
}

.va-center {
  position: relative;
  top: 50%;
  transform: translatey(-50%);
}

// element text align
.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

// float clear classes
.clear {
  clear: both;
}

.clearfix {
  &:before, &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// Page container

#page-container {
  overflow-x: hidden;
}

// Image box
.img-box {
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
  }

  &.img-box--full {
    img {
      width: 100%;
    }
  }
}

// Text box
.text-box {
  overflow: hidden;

  > * {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-dot {
    padding-left: 0;

    li {
      padding: 5px 0;
      line-height: 1.6em;

      &::before {
        content: "•";
        width: 10px;
        height: 10px;
        text-align: center;
        margin-right: 5px;
      }
    }
  }
}

// Hide recaptcha v3 badge

.grecaptcha-badge {
  display: none;
}

// Aspect ratio classes

[class*='ratio-'] {
  display: block;
  position: relative;

  > * {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

.email {
  &::after {
    content: attr(data-name) attr(data-domain);
  }
}

section {
  padding-top: 128px;
}

body {
  &.disabled {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: hidden !important;
    touch-action: none;
  }
}

* {
  scroll-behavior: smooth;
}

button, input {
  font-family: $font-primary;
}

img {
  border-radius: 5px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-right-32 {
  margin-right: 32px;
}
