////////////////////////////////////////////////////////////////////////
// VSBL AGENCY CSS GRID
////////////////////////////////////////////////////////////////////////
//
//
// Classes to use: [if default class names are: grid, row, col]
//
//  .grid                        === block container
//  .grid.grid--full             === full page width container
//  .row                         === block which has .col classes inside
//  .row.row--no-gutter          === block which has .col without gutters
//  .row.row--inline-top         === displays .col as inline-blocks. Vertical-align: top
//  .row.row--inline-middle      === --------------------------------- Vertical-align: middle
//  .row.row--inline-bottom      === --------------------------------- Vertical-align: bottom
//  .col                         === block which is is by default with gutter floated to left and 100% width
//  .col.md-1-2                  === class to define width of .col block till md breakpoint
//  .col.md-hide                 === hides block on md and smaller
//  .col.md-push-left-1-2        === puts margin-left on the block which is 1-2 width
//  .col.md-push-right-1-2       === puts margin-right on the block which is 1-2 width

////////////////////////////////////////////////////////////////////////
// GRID VARIABLES
////////////////////////////////////////////////////////////////////////

// HOW TO SETUP GRID
//   1 - Define class names
//   2 - Define grid width
//   3 - Define grid side margins
//   4 - Define col groups needed for project
//   5 - Define col responsive sizes. Other groups are needed for easier generation and better control
//   6 - Define col horizontal gutters. [can't be bigger than $grid-side-margin to avoid negative margins]
//   7 - Define grid breakpoints. Usually better leave default values {1440px, 1280px, 1024px, 768px, 568px}
//   8 - Define if you want to use push-left & push-right classes
//   9 - Define if you want to use hide classes
//  10 - Define if you want to use testing classes

//   1 - grid class names
$grid-container-name: "grid" !default;
$grid-row-name: "row" !default;
$grid-column-name: "col" !default;

//   2 - grid width
$grid-max-width: 1280px !default;

//   3 - grid side margins
$grid-side-margin: 20px !default;

//   4 - col groups
$grid-col-groups: 12, 4, 5, 10 !default;

//   5 - col sizes
$grid-col-sizes: xs sm md lg xl xxl !default;
$grid-col-xl-breakpoint-sizes: xs sm md lg xl !default;
$grid-col-lg-breakpoint-sizes: xs sm md lg !default;
$grid-col-md-breakpoint-sizes: xs sm md !default;
$grid-col-sm-breakpoint-sizes: xs sm !default;
$grid-col-xs-breakpoint-sizes: xs !default;

//   6 - col side gutters
$grid-col-gutter: 24px !default;

//   7 - grid breakpoints
$grid-breakpoint-xxl: 1900px;
$grid-breakpoint-xl: 1550px;
$grid-breakpoint-lg: 1280px;
$grid-breakpoint-md: 1024px;
$grid-breakpoint-sm: 768px;
$grid-breakpoint-xs: 540px;

//   8 - push-left & push-rght classes
$col-push-classes: true !default;

//   9 - hide classes
$col-hide-classes: true !default;

//  10 - testing classes
$grid-testing-classes: false !default;

////////////////////////////////////////////////////////////////////////
// GRID || ROW || COL
////////////////////////////////////////////////////////////////////////

// Grids
.#{$grid-container-name} {
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-side-margin;
  padding-right: $grid-side-margin;
  max-width: $grid-max-width;
  width: 100%;

  @media(max-width: $grid-breakpoint-md) {
    // padding-left: 90px
    // padding-right: 90px
  }

  &.#{$grid-container-name}--full {
    max-width: 100%;
    width: 100%;
  }

  &.#{$grid-container-name}--full-height {
    height: 100%;

    .#{$grid-row-name}:not(.row--default) {
      height: 100%;

      & > .col {
        height: 100%;
      }
    }
  }
}

// Rows
.#{$grid-container-name} {
  .#{$grid-row-name} {
    margin-left: -($grid-col-gutter / 2);
    margin-right: -($grid-col-gutter / 2);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-width: 100%;

    @media(max-width: $grid-breakpoint-md) {
      margin-left: -8px;
      margin-right: -8px;
    }

    &.#{$grid-row-name}--no-gutter {
      margin-left: 0;
      margin-right: 0;

      .#{$grid-column-name} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.#{$grid-row-name}--ver-stretch {
      align-items: stretch;

      .col {
        display: flex;

        & > * {
          // height: 100%
          width: 100%;
        }
      }
    }

    &.#{$grid-row-name}--ver-middle {
      align-items: center;
    }

    &.#{$grid-row-name}--ver-bottom {
      align-items: flex-end;
    }

    &.#{$grid-row-name}--column {
      flex-direction: column;
      align-items: flex-start;

      &.#{$grid-row-name}--ver-middle {
        justify-content: center;
      }

      &.#{$grid-row-name}--ver-bottom {
        justify-content: flex-end;
      }
    }

    &.#{$grid-row-name}--hor-center {
      justify-content: center;
    }

    &.#{$grid-row-name}--hor-between {
      justify-content: space-between;
    }

    &.#{$grid-row-name}--hor-bottom {
      justify-content: flex-end;
    }
  }
}

// Cols
.#{$grid-container-name} {
  .#{$grid-row-name} {
    .#{$grid-column-name} {
      padding-left: $grid-col-gutter / 2;
      padding-right: $grid-col-gutter / 2;
      position: relative;
      width: 100%;

      @media(max-width: $grid-breakpoint-md) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    // $grid-breakpoint-xl[1440px] >= SCREEN WIDTH
    @each $size in $grid-col-sizes {
      .#{$size}-1-1 {
        width: 100%;
      }

      @each $group in $grid-col-groups {
        @for $i from 1 through $group {
          .#{$size}-#{$i}-#{$group} {
            width: percentage($i / $group);
          }
        }
      }
    }

    // $grid-breakpoint-xl[1440px] < SCREEN WIDTH
    @media (max-width: $grid-breakpoint-xl) {
      [class*="xxl-"] {
        width: 100%;
      }

      @each $size in $grid-col-xl-breakpoint-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-#{$i}-#{$group} {
              width: percentage($i / $group);
            }
          }
        }
      }
    }

    // $grid-breakpoint-lg[1280px] < SCREEN WIDTH
    @media (max-width: $grid-breakpoint-lg) {
      [class*="xxl-"], [class*="xl-"] {
        width: 100%;
      }

      @each $size in $grid-col-lg-breakpoint-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-#{$i}-#{$group} {
              width: percentage($i / $group);
            }
          }
        }
      }
    }

    // $grid-breakpoint-md[1024px] < SCREEN WIDTH
    @media (max-width: $grid-breakpoint-md) {
      [class*="xxl-"], [class*="xl-"], [class*="lg-"] {
        width: 100%;
      }

      @each $size in $grid-col-md-breakpoint-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-#{$i}-#{$group} {
              width: percentage($i / $group);
            }
          }
        }
      }
    }

    // $grid-breakpoint-sm[768] < SCREEN WIDTH
    @media (max-width: $grid-breakpoint-sm) {
      [class*="xxl-"], [class*="xl-"], [class*="lg-"], [class*="md-"] {
        width: 100%;
      }

      @each $size in $grid-col-sm-breakpoint-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-#{$i}-#{$group} {
              width: percentage($i / $group);
            }
          }
        }
      }
    }

    // $grid-breakpoint-xs[568px] < SCREEN WIDTH
    @media (max-width: $grid-breakpoint-xs) {
      [class*="xxl-"], [class*="xl-"], [class*="lg-"], [class*="md-"], [class*="sm-"] {
        width: 100%;
      }

      @each $size in $grid-col-xs-breakpoint-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-#{$i}-#{$group} {
              width: percentage($i / $group);
            }
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////
// HIDE || PUSH || PULL
////////////////////////////////////////////////////////////////////////

// Hide classes
@if $col-hide-classes {
  .md-show {
    display: none;
  }

  // $grid-breakpoint-xl[1440px] < SCREEN WIDTH
  @media (max-width: $grid-breakpoint-xl) {
    .xl-hide {
      display: none;
    }

    .xl-show {
      display: block;
    }
  }

  // $grid-breakpoint-lg[1280px] < SCREEN WIDTH
  @media (max-width: $grid-breakpoint-lg) {
    .lg-hide {
      display: none;
    }

    .lg-show {
      display: block;
    }
  }

  // $grid-breakpoint-md[1024px] < SCREEN WIDTH
  @media (max-width: $grid-breakpoint-md) {
    .md-hide {
      display: none;
    }

    .md-show {
      display: block;
    }
  }

  // $grid-breakpoint-sm[768px] < SCREEN WIDTH
  @media (max-width: $grid-breakpoint-sm) {
    .sm-hide {
      display: none;
    }

    .sm-show {
      display: block;
    }
  }

  // $grid-breakpoint-xs[568px] < SCREEN WIDTH
  @media (max-width: $grid-breakpoint-xs) {
    .xs-hide {
      display: none;
    }

    .xs-show {
      display: block;
    }
  }
}

// Push & Pull classes
@if $col-push-classes {
  .#{$grid-container-name} {
    .#{$grid-row-name} {
      // $grid-breakpoint-xl[1440px] >= SCREEN WIDTH
      @each $size in $grid-col-sizes {
        @each $group in $grid-col-groups {
          @for $i from 1 through $group {
            .#{$size}-push-left-#{$i}-#{$group} {
              margin-left: percentage($i / $group);
            }

            .#{$size}-push-right-#{$i}-#{$group} {
              margin-right: percentage($i / $group);
            }
          }
        }
      }

      // $grid-breakpoint-xl[1440px] < SCREEN WIDTH
      @media (max-width: $grid-breakpoint-xl) {
        [class*="xxl-push-left-"] {
          margin-left: 0;
        }

        [class*="xxl-push-right-"] {
          margin-right: 0;
        }

        @each $size in $grid-col-xl-breakpoint-sizes {
          @each $group in $grid-col-groups {
            @for $i from 1 through $group {
              .#{$size}-push-left-#{$i}-#{$group} {
                margin-left: percentage($i / $group);
              }

              .#{$size}-push-right-#{$i}-#{$group} {
                margin-right: percentage($i / $group);
              }
            }
          }
        }
      }

      // $grid-breakpoint-lg[1280px] < SCREEN WIDTH
      @media (max-width: $grid-breakpoint-lg) {
        [class*="xxl-push-left-"], [class*="xl-push-left-"] {
          margin-left: 0;
        }

        [class*="xxl-push-right-"], [class*="xl-push-right-"] {
          margin-right: 0;
        }

        @each $size in $grid-col-lg-breakpoint-sizes {
          @each $group in $grid-col-groups {
            @for $i from 1 through $group {
              .#{$size}-push-left-#{$i}-#{$group} {
                margin-left: percentage($i / $group);
              }

              .#{$size}-push-right-#{$i}-#{$group} {
                margin-right: percentage($i / $group);
              }
            }
          }
        }
      }

      // $grid-breakpoint-md[1024px] < SCREEN WIDTH
      @media (max-width: $grid-breakpoint-md) {
        [class*="xxl-push-left-"], [class*="xl-push-left-"], [class*="lg-push-left-"] {
          margin-left: 0;
        }

        [class*="xxl-push-right-"], [class*="xl-push-right-"], [class*="lg-push-right-"] {
          margin-right: 0;
        }

        @each $size in $grid-col-md-breakpoint-sizes {
          @each $group in $grid-col-groups {
            @for $i from 1 through $group {
              .#{$size}-push-left-#{$i}-#{$group} {
                margin-left: percentage($i / $group);
              }

              .#{$size}-push-right-#{$i}-#{$group} {
                margin-right: percentage($i / $group);
              }
            }
          }
        }
      }

      // $grid-breakpoint-sm[768px] < SCREEN WIDTH
      @media (max-width: $grid-breakpoint-sm) {
        [class*="xxl-push-left-"], [class*="xl-push-left-"], [class*="lg-push-left-"], [class*="md-push-left-"] {
          margin-left: 0;
        }

        [class*="xxl-push-right-"], [class*="xl-push-right-"], [class*="lg-push-right-"], [class*="md-push-right-"] {
          margin-right: 0;
        }

        @each $size in $grid-col-sm-breakpoint-sizes {
          @each $group in $grid-col-groups {
            @for $i from 1 through $group {
              .#{$size}-push-left-#{$i}-#{$group} {
                margin-left: percentage($i / $group);
              }

              .#{$size}-push-right-#{$i}-#{$group} {
                margin-right: percentage($i / $group);
              }
            }
          }
        }
      }

      // $grid-breakpoint-xs[568px] < SCREEN WIDTH
      @media (max-width: $grid-breakpoint-xs) {
        [class*="xxl-push-left-"], [class*="xl-push-left-"], [class*="lg-push-left-"], [class*="md-push-left-"], [class*="sm-push-left-"] {
          margin-left: 0;
        }

        [class*="xxl-push-right-"], [class*="xl-push-right-"], [class*="lg-push-right-"], [class*="md-push-right-"], [class*="sm-push-right-"] {
          margin-right: 0;
        }

        @each $size in $grid-col-xs-breakpoint-sizes {
          @each $group in $grid-col-groups {
            @for $i from 1 through $group {
              .#{$size}-push-left-#{$i}-#{$group} {
                margin-left: percentage($i / $group);
              }

              .#{$size}-push-right-#{$i}-#{$group} {
                margin-right: percentage($i / $group);
              }
            }
          }
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////
// TESTING STYLING
////////////////////////////////////////////////////////////////////////

@if $grid-testing-classes {
  .#{$grid-container-name} {
    background: #bada55;
    padding-top: 20px;
    padding-bottom: 20px;

    .#{$grid-row-name} {
      background: yellow;
      padding-top: 20px;
      padding-bottom: 20px;

      .#{$grid-column-name} {
        border: 1px solid red;

        > * {
          display: block;
          background: pink;
        }
      }
    }
  }
}
