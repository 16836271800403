.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
  left: 0;
  display: none;

  .cancel {
    font-size: 0.857em;
    color: $red;
    font-weight: 500;
  }

  @media all and (max-width: 1000px) {
    padding: 16px;
  }

  &.js-active {
    display: block;
  }

  label {
    display: block;
    font-size: 0.7em;
    color: $grey;
    margin: 16px 0 3px 0;
  }

  .form-group__checkbox--single {
    margin-right: 20px;
  }

  input[type="checkbox"] ~ label {
    margin: 0;
  }

  .btn {
    display: flex;
    align-items: center;
  }

  &__timer {
    color: $color-primary;
    font-weight: 500;
    font-size: 1.5em;
    padding-left: 25px;
    position: relative;

    &::before {
      font-family: "Glyphter";
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;

      @include translateTop;

      text-align: center;
      line-height: 20px;
      content: "Q";
    }
  }
}

.popup__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.67);
}

.popup__content {
  background: $white;
  padding: 32px 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 565px;

  @media all and (max-width: 1000px) {
    width: calc(100% - 32px);
    padding: 24px;
  }

  .tabs-list {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &--big {
    width: 75%;
    height: 95%;
  }

  &--medium {
    width: 480px;
    padding: 24px 75px;

    @media all and (max-width: 1000px) {
      width: calc(100% - 32px);
      padding: 24px;
    }

    form {
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        margin-top: 25px;
      }
    }

    .btn {
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        margin-top: 25px;
      }
    }
  }

  &--small {
    width: 385px;

    @media all and (max-width: 1000px) {
      width: calc(100% - 32px);
    }
  }

  &--form-submit {
    padding: 24px 155px;

    form {
      margin: 32px 0 24px 0;
    }

    .icon-back {
      display: flex;
      position: absolute;
      top: 30px;
      left: 25px;
      font-weight: 500;
      color: $grey-dark;

      &::before {
        content: url("../../../img/icons/arrow-left.svg");
        margin-right: 16px;
      }
    }

    @media all and (max-width: 1000px) {
      padding: 24px;
    }
  }
}

.popup__close {
  width: 40px;
  height: 40px;
  position: absolute;
  font-size: 0.8em;
  top: 0;
  right: 0;

  @include fleXCenterCenter;

  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  &::before {
    font-family: "Glyphter";
    width: 20px;
    height: 20px;
    content: "×";
    text-align: center;
    line-height: 20px;
    font-size: 27px;
  }

  &:hover {
    opacity: 1;
  }
}

.popup__smartid {
  .popup__content {
    width: 415px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .standard-title {
      margin: 32px 0 18px 0;
    }

    .code {
      font-size: 3em;
      font-weight: 500;
    }

    .content__message {
      display: flex;
      align-items: center;
      margin: 20px 0 32px 0;

      p {
        color: $grey;
        font-size: 0.857em;
        margin-left: 12px;
      }
    }

    @media all and (max-width: 1000px) {
      width: calc(100% - 32px);
    }
  }
}

.popup__redirect {
  .popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .standard-title {
    font-size: 1em;
    margin-top: 25px;
  }

  .status {
    font-size: 1.5em;
    color: $color-primary;
    margin: 18px 0 30px 0;

    &::before {
      width: 22px;
      height: 22px;
      color: $color-primary;
    }
  }
}

.popup__user {
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    color: $grey-dark;
  }

  span {
    color: $color-primary;
  }

  .btn {
    margin-top: 43px;

    @media all and (max-width: 1000px) {
      margin-top: 25px;
    }
  }
}

.popup__assign {
  &--subtitle {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;

    p {
      color: $grey;
      margin-top: 3px;
    }
  }

  .popup__filter {
    form {
      justify-content: center;
    }
  }

  .form-group__checkbox {
    @media all and (max-width: 1000px) {
      position: absolute;
      left: 0;

      @include translateTop;
    }

    label {
      margin: 0;
    }
  }

  .list__name {
    padding-left: 20px;

    @media all and (max-width: 1000px) {
      padding-left: 0;
    }
  }

  .popup__list {
    border-bottom: 1px solid $grey-lightest;
    padding-bottom: 10px;

    @media all and (max-width: 1000px) {
      li {
        padding-left: 40px;
      }
    }
  }

  .btn {
    margin-top: 24px;
  }
}

.popup__locked {
  .popup__content {
    padding-top: 54px;

    p {
      margin-top: 9px;
      margin-bottom: 24px;
    }

    .btn {
      margin-top: 24px;
    }
  }
}

.popup--session {
  .standard-title {
    margin-top: 25px;
  }

  .popup__timer {
    display: inline-block;
    margin: 18px 0 30px;
  }
}

.popup--pdf-view {
  .popup__content {
    display: flex;
    flex-direction: column;
  }
}

.modal-hubspot-form {
  width: 100%;
  height: 100%;
  min-width: 320px;
  position: relative;

  &__iframe {
    border: none;
    width: 100%;
    max-width: 768px;
    min-width: 320px;
    height: 620px;
    position: relative;
  }
}