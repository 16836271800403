#news {
  .show-mobile {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  .row {
    &.row-news {
      .col {
        @media screen and (max-width: 1024px) {
          margin-bottom: 56px !important;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 63px !important;
        }

        @media screen and (max-width: 540px) {
          margin-bottom: 48px !important;
        }
      }
    }
  }

  .news__title {
    & + p {
      margin-top: 32px;

      @media screen and (max-width: 1024px) {
        margin-top: 24px;
      }
    }
  }

  .news__link {
    display: block;
    color: $color-primary;
    transition: color 0.4s ease-out;
    will-change: color;

    @media (hover: hover) {
      &:hover {
        color: $blue;

        .news__wrapper__img {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
    }

    .news__wrapper {
      overflow: hidden;
      border-radius: 5px;

      &__img {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        padding-top: calc( (394 / 612) * 100% );
        transition: transform 0.4s ease-out;
        will-change: transform;
      }
    }

    .news__subtitle {
      font-size: 1.42em;
      line-height: 26px;
    }

    h4:not(.news__subtitle) {
      line-height: 39.93px;
      font-weight: $bold;
      font-size: 2.2em;
    }

    h4 {
      margin-top: 24px;

      @media screen and (max-width: 1024px) {
        margin-top: 16px;
        font-size: 1.21em !important;
        font-weight: $bold;
        line-height: 22px !important;
      }
    }

    .news__description {
      margin-top: 24px;
      color: $grey-darker;

      @media screen and (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }

  .btn {
    margin-top: 75px;

    @media screen and (max-width: 1024px) {
      margin-top: 56px;
    }

    @media screen and (max-width: 540px) {
      margin-top: 40px;
    }
  }

  &.news--list {
    .row {
      &-news {
        .col {}

        margin-bottom: 84px;

        @media screen and (max-width: 1024px) {
          margin-bottom: 0px;
        }

        &:nth-child(3n+1) {
          transform: translateY(66px);
          padding-right: 34px;

          @media screen and (max-width: 1024px) {
            transform: none;
            padding-right: 8px;
          }
        }

        &:nth-child(3n+2) {
          transform: translateY(142px);
          padding: 0 17px;

          @media screen and (max-width: 1024px) {
            transform: none;
            padding: 0 8px;
          }
        }

        &:nth-child(3n+3) {
          padding-left: 34px;

          @media screen and (max-width: 1024px) {
            padding-left: 8px;
          }
        }
      }

      &-pagination {
        margin-top: 142px;

        @media screen and (max-width: 1024px) {
          margin-top: 32px;
        }
      }
    }
  }

  &.news--small {
    .row {
      &.row-news {
        margin-top: 56px;

        .col {
          &:first-child {
            margin-top: 56px;
          }

          &:nth-child(2) {
            margin-top: 142px;
          }

          @media screen and (max-width: 1024px) {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  &.news--large {
    .row {
      &.row-news {
        margin-top: 56px;

        @media screen and (max-width: 768px) {
          margin-top: 36px;
        }

        @media screen and (max-width: 540px) {
          margin-top: 32px;
        }

        .col {
          &:nth-child(1) {
            margin-bottom: 85px;
          }

          &:nth-child(2) {
            margin-top: 111px;

            @media screen and (max-width: 1024px) {
              margin-top: 0px;
            }
          }

          &:nth-child(3) {
            margin-top: 48px;

            @media screen and (max-width: 1024px) {
              margin-top: 0px;
            }
          }

          &:nth-child(4) {
            margin-top: 48px;

            @media screen and (max-width: 1024px) {
              margin-top: 0px;
            }
          }

          &:nth-child(5) {
            margin-top: 111px;

            @media screen and (max-width: 1024px) {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
