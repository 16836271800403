////////////////////////////////////////////////////////////////////////
// THEME TYPOGRAPHY
////////////////////////////////////////////////////////////////////////

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding: 0;
}

body {
  font-size: $body-font-size;
  font-family: $font-primary;
  color: $color-primary;
}

h1 {
  font-size: 4.3em;
  font-weight: $bold;

  @media screen and (max-width: 1024px) {
    font-size: 3.79em;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.71em;
  }
}

h2 {
  font-size: 3.2em;
  font-weight: $medium;

  @media screen and (max-width: 1024px) {
    font-size: 2.71em;
  }

  @media screen and (max-width: 540px) {
    font-size: 2em;
  }
}

h3 {
  font-size: 2.2em;
  font-weight: $medium;

  @media screen and (max-width: 1024px) {
    font-size: 1.72em;
  }
}

h4 {
  font-size: 1.7em;
  font-weight: $medium;

  @media screen and (max-width: 1024px) {
    font-size: 1.21em;
  }
}

h5 {
  font-size: 1.14em;
  font-weight: $medium;

  @media screen and (max-width: 1024px) {
    font-size: 1.14em;
  }
}

h6 {
  font-size: 1em;
  font-weight: $medium;

  @media screen and (max-width: 1024px) {
    font-size: 1.14em;
  }
}

p {
  line-height: $body-line-height;
}

a {
  text-decoration: none;
  color: $blue;
}

q {
  font-size: 1.5em;
}

.section-title {
  font-size: 0.86em;
  color: $grey-light;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: $medium;

  &--lg {
    margin-bottom: 70px;
  }

  &--md {
    margin-bottom: 56px;
  }

  &--sm {
    margin-bottom: 36px;
  }

  &--xs {
    margin-bottom: 16px;
  }
}

article {
  p {
    font-size: 1.43em;
    line-height: 32px;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 1.14em;
      line-height: 26px;
    }
  }

  a {
    text-decoration: underline;
  }

  h2 {
    margin-bottom: 74px;

    @media screen and (max-width: 768px) {
      margin-bottom: 34px;
    }
  }

  h3, h4 {
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      margin-bottom: 22px;
    }
  }

  p {
    & + h3, & + h4 {
      margin-top: 56px;

      @media screen and (max-width: 768px) {
        margin-top: 36px;
      }
    }
  }

  ul {
    &.list-check {
      li {
        position: relative;
        line-height: 24px;
        margin-bottom: 20px;
        padding-left: 30px;
        font-size: 1.14em;

        &::before {
          content: url("../../../img/icons/checkmark-green.svg");
          position: absolute;
          left: 0;
        }
      }
    }

    &.list-bullet {
      li {
        position: relative;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: $medium;
        font-size: 1.29em;
        padding-left: 21px;

        &::before {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          width: 6px;
          height: 6px;
          background-color: $color-primary;
          border-radius: 50%;
        }
      }
    }

    &.privacy-list-bullet {
      padding-left: 24px;

      li {
        position: relative;
        line-height: 32px;
        margin-bottom: 10px;
        font-size: 1em;
        padding-left: 21px;

        &::before {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          width: 6px;
          height: 6px;
          background-color: $color-primary;
          border-radius: 50%;
        }
      }
    }
  }

  img {
    width: 100%;
    margin: 56px 0;

    &:first-child {
      margin: 0 0 96px 0;
    }
  }

  blockquote {
    border-left: 4px solid $blue;
    margin: 56px 0;
    padding: 0 0 0 25px;

    p {
      margin: 0 0 16px 0;
      font-style: italic;
    }

    small {
      font-size: 1.43em;
      font-weight: $medium;
    }
  }

  table {
    border: 2px solid $grey-lighter;
    border-collapse: collapse;
    width: 100%;
    margin-top: 56px;

    tr {
      &:nth-child(even) {
        background-color: $grey-lighter;
      }
    }

    th {
      padding: 12px 24px;
      text-align: left;

      &:nth-child(1), &:nth-child(2) {
        width: 20%;
      }
    }

    td {
      padding: 12px 24px;
    }
  }
}

table {
  table-layout: fixed;
}

.cookies-details {
  overflow-x: auto;
  margin-bottom: 3em;
}

.privacy_cookies_table {
  width: auto;
  min-width: 1000px;
  margin-top: 0;

  p {
    font-size: 1em;
  }
}


