// 1 - Vendors
@import "1_vendors/_1-swiper";
@import "1_vendors/_3-dropzone-progress";

// 2 - Reset
@import "2_reset/1-normalize";

// 3 - Setup
@import "3_setup/1-variables";
@import "3_setup/2-utilities";
@import "3_setup/3-typography";
@import "3_setup/4-fonts";
@import "3_setup/5-grid";
@import "3_setup/6-animations";
@import "3_setup/7-mixins";

// 4 - Components
@import "4_components/1-buttons";
@import "4_components/_2-text-block";
@import "4_components/_3-companies";
@import "4_components/_4-lists";
@import "4_components/_5-forms";
@import "4_components/_6-cookie-notice";
@import "4_components/_7-cards";
@import "4_components/_8-blue-box";
@import "4_components/_9-accordion";
@import "4_components/_10-pagination";

// 5 - Layout
@import "5_layout/1-header";
@import "5_layout/2-footer";
@import "5_layout/_3-intro";
@import "5_layout/_4-news";
@import "5_layout/_5-industries";
@import "5_layout/_6-people";
@import "5_layout/_7-pricing";
@import "5_layout/_8-roadmap";
@import "5_layout/_9-popup";

// 6 - Pages
@import "6_pages/1-contacts";
@import "6_pages/_2-page-not-found";
@import "6_pages/_3-privacy-policy";
@import "6_pages/_4-career-single";
@import "6_pages/_5-support";
@import "6_pages/_6-news-single";

// 7 - Responsive
@import "7_responsive/1-responsive-layout";

#intro {
  background-image: url('../../img/hero/index-hero.png');
}

#intro.intro--no-bg {
  background: none;
}

.news__wrapper__img {
  background-image: url('../../img/news/news-img.png');
}

.support--intro {
  background-image: url('../../img/hero/support-hero.png')!important;
}

.about--hero {
  background-image: url('../../img/hero/about-hero.png')!important;
}

.intro__content {
  text-align: center;
}

span.link {
  color: $white;
}

.industries__item__img__gaming {
  background-image: url('../../img/swiper/gaming.png');
}

.industries__item__img__fintech {
  background-image: url('../../img/hero/use-cases-hero.png');
}

.industries__item__img__trading {
  background-image: url('../../img/swiper/trading.png');
}

.industries__item__img__electronic_money {
  background-image: url('../../img/swiper/institution.png');
}

.industries__item__img__industries {
  background-image: url('../../img/swiper/payment.png');
}

.people__card__img {
  background-image: url('../../img/people/person.png');
}

.btn--primary--white {
  background: $white;
  color: $black;
}

.intro__collage__wrapper {
  background-image: url('../../img/images/intro-img.png');
}

.form_sent__success {
  background-color: $green-lightest;
  color: $black;
  text-align: center;
  padding: 20px 20px;
  margin-bottom: 1em;
}

.text__center {
  text-align: center;
}

.platforms__text {
  color: $white;
}

.platforms__text h1,
.intro__content h1 {
  font-size: 3.3em;
}

.about-us-heading {
  margin-bottom: 20px;
}

ul.list-check li {
  line-height: 26px;
  margin-bottom: 10px;
}

ul.list-check li:before {
  content: url('../../img/icons/checkmark-green.svg');
  margin-right: 20px;
}

.companies__list img {
  max-width: 190px;
  height: 61px;
  margin: 10px;
}

.contact_form__error {
  color: $red-soft;
  height: 20px;
}

.dropdown__item {
  margin-bottom: 10px;
}

.dropdown__item__text {
  margin-bottom: 0;
  margin-top: 6px;
}

img.investor__images {
  width: 30%;
  height: auto;
}

img.bnp__logo {
  width: 210px;
  height: auto;
}

img.lku__logo {
  width: 160px;
  height: auto;
}

img.lendstream__logo {
  width: 190px;
  height: auto;
}

img.enefit__logo {
  width: 230px;
  height: auto;
}

.icon_linkedIn {
  width: 16%;
  height: auto;
}

.icon_facebook {
  width: 16%;
  height: auto;
}

.text-block__list__item .text h2 {
  position: relative;
  font-size: 1.7em;
}

h2.careers-heading {
  margin-bottom: 2em;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-right-32 {
  margin-right: 32px;
}